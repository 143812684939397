<template>
  <div class="containerx">
    <div v-if="project==0" class="project0">
      <div class="title">公司列表</div>
      <div class="table">
        <div class="tr" style="background: linear-gradient(0deg, rgba(43, 201, 222, 0.6), rgba(43, 201, 222, 0.6));">
          <div class="td1" style="color: #FFFFFF;">
            <p>序号</p>
          </div>
          <div class="td2" style="color: #FFFFFF;">
            <p>公司名称</p>
          </div>
        </div>
        <div @click="handleTb0(item)" class="tr" :class="index % 2 == 0?'bg1':'bg2'"
        v-for="(item, index) in tableData0">
          <div class="td1">
            <p>{{ index+1 }}</p>
          </div>
          <div class="td2" style="cursor: pointer;">
            <p>{{item.projectName}}</p>
          </div>
        </div>
      </div>
      <div class="pagination" style="margin: 2rem 0;margin-right: 1rem;">
        <el-pagination style="color: white" background @current-change="currentChange0" @size-change="sizeChange0"
          :current-page.sync="current0" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
          :total="total0">
        </el-pagination>
      </div>
    </div>
    <div v-if="project==1" class="project1">
      <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
        <div class="title">
          <span @click="project=0" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">公司列表&nbsp; / &nbsp;</span>
          <span style="color: rgba(43, 201, 222, 1);cursor: pointer;">{{xmcj1}}</span>
        </div>
        <div style="display: flex;">
          <div @click="project=0" class="back">返回</div>
        </div>
      </div>
      <div class="table">
        <div class="tr bg">
          <div class="td1">
            <p>序号</p>
          </div>
          <div class="td2">
            <p>项目层级一</p>
          </div>
        </div>
        <div @click="handleTb1(item)" class="tr" v-for="(item, index) in tableData1" :class="index % 2 == 0?'bg1':'bg2'">
          <div class="td1">
            <p>{{ index+1 }}</p>
          </div>
          <div class="td2">
            <p>{{item.levelName}}</p>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination style="color: white" background @current-change="currentChange1" @size-change="sizeChange1"
          :current-page.sync="current1" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
          :total="total1">
        </el-pagination>
      </div>

    </div>

    <div v-if="project==2" class="project1">
      <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
        <div class="title">
          <span @click="project=0" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">公司列表&nbsp; / &nbsp;</span>
          <span @click="project=1" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj1}}&nbsp; / &nbsp;</span>
          <span style="color: rgba(43, 201, 222, 1);cursor: pointer;">{{xmcj2}}</span>
        </div>
        <div style="display: flex;">
          <div @click="project=1" class="back">返回</div>
        </div>
      </div>
      <div class="table">
        <div class="tr bg">
          <div class="td1">
            <p>序号</p>
          </div>
          <div class="td2">
            <p>项目层级二</p>
          </div>
        </div>
        <div @click="handleTb2(item)" class="tr" v-for="(item, index) in tableData2" :class="index % 2 == 0?'bg1':'bg2'">
          <div class="td1">
            <p>{{ index+1 }}</p>
          </div>
          <div class="td2">
            <p>{{item.levelName}}</p>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination style="color: white" background @current-change="currentChange2" @size-change="sizeChange2"
          :current-page.sync="current2" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
          :total="total2">
        </el-pagination>
      </div>

    </div>

    <div v-if="project==3" class="project1">
      <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
        <div class="title">
          <span @click="project=0" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">公司列表&nbsp; / &nbsp;</span>
          <span @click="project=1" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj1}}&nbsp; / &nbsp;</span>
          <span @click="project=2" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj2}}&nbsp; / &nbsp;</span>
          <span style="color: rgba(43, 201, 222, 1);cursor: pointer;">{{xmcj3}}</span>
        </div>
        <div style="display: flex;">
          <div @click="project=2" class="back">返回</div>
        </div>
      </div>
      <div class="table">
        <div class="tr bg">
          <div class="td1">
            <p>序号</p>
          </div>
          <div class="td2">
            <p>项目层级三</p>
          </div>
        </div>
        <div @click="handleTb3(item)" class="tr" v-for="(item, index) in tableData3" :class="index % 2 == 0?'bg1':'bg2'">
          <div class="td1">
            <p>{{ index+1 }}</p>
          </div>
          <div class="td2">
            <p>{{item.levelName}}</p>
          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination style="color: white" background @current-change="currentChange3" @size-change="sizeChange3"
          :current-page.sync="current3" :page-sizes="[10, 15, 20]" layout="total, sizes, prev, pager, next, jumper"
          :total="total3">
        </el-pagination>
      </div>

    </div>

    <div v-if="project==99">
      <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
        <div class="title">
          <span @click="project=0" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">公司列表&nbsp; / &nbsp;</span>
          <span v-if="projects>1" @click="project=1" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj1}}&nbsp; / &nbsp;</span>
          <span v-if="projects>2" @click="project=2" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj2}}&nbsp; / &nbsp;</span>
          <span v-if="projects>3" @click="project=3" style="color: rgba(43, 222, 201, 0.4);cursor: pointer;">{{xmcj3}}&nbsp; / &nbsp;</span>
          <span style="color: rgba(43, 201, 222, 1);cursor: pointer;">环境数据</span>
        </div>
        <div style="display: flex;">
          <div @click="project=3" class="back">返回</div>
        </div>
      </div>
      <hjjc :projectId="projectId1" :projectId_cx="projectId_cx" :levelId="level_cx"></hjjc>
    </div>
  </div>
</template>

<script>
  import hjjc from '/src/views/ZXJC/HJSJ/index3.vue'
  export default {
    name: 'index1',
    components: {
      hjjc
    },
    data() {
      return {
        project: 0,
        projects: 0,
        tableData0: [],
        current0: 1,
        size0: 10,
        total0: 0,
        tableData1: [],
        projectId1: null,
        current1: 1,
        size1: 10,
        total1: 0,
        // 22222
        tableData2: [],
        projectId2: null,
        current2: 1,
        size2: 10,
        total2: 0,
        // 3333333333
        tableData3: [],
        projectId3: null,
        current3: 1,
        size3: 10,
        total3: 0,
        level_cx:null,
        projectId_cx:null,
        xmcj1: '项目层级一',
        xmcj2: '项目层级二',
        xmcj3: '项目层级三',
      }
    },
    mounted() {
      this.getPage()
    },
    methods: {
      getPage() {
        // 获取项目列表
        this.$newPost('/project/page', {
          "page": this.current0,
          "size": this.size0
        }).then(res => {
          console.log(res)
          this.tableData0 = res.data.records
          this.total0 = res.data.total
        })
      },
      currentChange0(e) {
        console.log(e)
        this.current0 = e
        this.getPage()
      },
      sizeChange0(e) {
        this.size0 = e
        this.getPage()
      },
      // 点击表格
      handleTb0(item) {
        console.log(item)
        this.xmcj1 = item.projectName // 面包屑名称
        this.projectId1 = item.id
        this.projectId_cx = item.projectId
        this.level_cx = item.id
        // 页码 页数还原
        this.current1 = 1
        this.size1 = 10
        this.getLevel1()
      },
      // 分页查询层级列表
      // 11111111111111
      getLevel1() {
        var obj = {
          "page": this.current1,
          "size": this.size1,
          parentId: 0,
          projectId: this.projectId1,
        }
        this.$newPost('/level/page', obj).then(res => {
          console.log(res)
          if(res.data.records.length>0) {
            this.tableData1 = res.data.records
            this.total1 = res.data.total
            this.project = 1
          } else {
            this.tableData1 = []
            this.project = 99
            this.projects = 1
          }
        })
      },
      currentChange1(e) {
        console.log(e)
        this.current1 = e
        this.getLevel1()
      },
      sizeChange1(e) {
        this.size1 = e
        this.getLevel1()
      },
      // 点击表格
      handleTb1(item) {
        console.log(item)
        this.xmcj2 = item.levelName // 面包屑名称
        this.projectId2 = item.projectId
        this.parentId2 = item.id
        this.level_cx = item.id
        this.projectId_cx = item.projectId
        // 页码 页数还原
        this.current2 = 1
        this.size2 = 10
        this.getLevel2()
      },

      // 2222222222
      getLevel2() {
        var obj = {
          "page": this.current2,
          "size": this.size2,
          parentId: this.parentId2,
          projectId: this.projectId2,
        }
        this.$newPost('/level/page', obj).then(res => {
          console.log(res)
          if(res.data.records.length>0) {
            this.tableData2 = res.data.records
            this.total2 = res.data.total
            this.project = 2
          } else {
            this.tableData2 = []
            this.project = 99
            this.projects = 2
          }
        })
      },
      currentChange2(e) {
        console.log(e)
        this.current2 = e
        this.getLevel2()
      },
      sizeChange2(e) {
        this.size2 = e
        this.getLevel2()
      },

      handleTb2(item) {
        console.log(item)
        this.xmcj3 = item.levelName // 面包屑名称
        this.projectId3 = item.projectId
        this.parentId3 = item.id
        this.level_cx = item.id
        // 页码 页数还原
        this.current3 = 1
        this.size3 = 10
        this.getLevel3()
      },

      // 3333333333
      getLevel3() {
        var obj = {
          "page": this.current3,
          "size": this.size3,
          parentId: this.parentId3,
          projectId: this.projectId3,
        }
        this.$newPost('/level/page', obj).then(res => {
          console.log(res)
          if(res.data.records.length>0) {
            this.tableData3 = res.data.records
            this.total3 = res.data.total
            this.project = 3
          } else {
            this.tableData3 = []
            this.project = 99
            this.projects = 3
          }
        })
      },
      currentChange3(e) {
        console.log(e)
        this.current3 = e
        this.getLevel3()
      },
      sizeChange3(e) {
        this.size3 = e
        this.getLevel3()
      },
      handleTb3(item) {
        this.project = 99
        this.projects = 4
        this.level_cx = item.id
      },

    },
  }
</script>

<style lang="scss" scoped>
  .bg {
    background-color: rgba(43, 201, 222, 0.6);
  }

  .bg1 {
    background-color: rgba(162, 162, 162, 0.2);
  }

  .bg2 {
    background: rgba(14, 242, 242, 0.3);
  }
  .containerx {
    color: #ffffff;
    overflow: auto;
    background-color: #192534;
  }

  .title {
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: 2rem;
    //margin-top: 2rem;
    height: 5rem;
    line-height: 5rem;
  }

  .table {
    width: 154.6rem;
    max-height: 60rem;
    overflow: auto;
    margin: 0 auto;
    // border: 1px solid rgba(43, 201, 222, 0.6);
    .tr {
      display: flex;
      // margin-top: 1rem;
      padding: .5rem 0;

      .td1 {
        font-size: 1.4rem;
        width: 6.6rem;
        height: 4.2rem;
        margin-right: 0.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
      }

      .td2 {
        font-size: 1.4rem;
        width: 147.6rem;
        height: 4.2rem;
        display: flex;
        align-items: center;
        padding-left: 2rem;
        color: #FFFFFF;
      }
    }
    .tr:hover {
      background-color: rgba(117, 117, 117, 0.4);
    }
  }

  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
  }
  .back {
    border: 1px solid #1CB8B8;
    border-radius: 0.5rem;
    width: 6.7rem;
    height: 3.8rem;
    line-height: 3.8rem;
    text-align: center;
    color: rgba(28, 184, 184, 1);
    font-size: 1.4rem;
    margin-right: 2rem;
    cursor: pointer;
  }
</style>
